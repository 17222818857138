import Image from "../../../assets/images/Image"

/*


      {
        type: "text",
        title: {},
        text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
      },

      {
        type: "content-text",
        title: {},
        text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
      },
      
      { type: "after", size: "lg", afterImg: "kitchen1After" },

      {
        type: "comparison",
        size: "sm",
        beforeImg: "exterior1Before",
        beforeImgStyle: {objectFit:'contain'},
        afterImg: "kitchen1After",
        afterStyle: {},
      },


*/
export const landsdowneData = [
  {
    name: "intro",
    textColumn: "left",
    col1: [
      {
        type: "text",
        title: "",
        text: `This is one of our favourite layout changes. We wanted downstairs to flow better as it was very 
        disjointed so we put two French doors either side of the fireplace to connect the separate living to the dining, 
        kitchen and the other side opening to the sun lit hallway and toilet. This changed the whole feel of the property. `,
      },
      {
        type: "comparison",
        size: "md",
        beforeImg: "lounge2Before",
        beforeImgStyle: { objectFit: "contain" },
        afterImg: "lounge2After",
        afterStyle: {},
      },
      {
        type: "comparison",
        size: "sm",
        beforeImg: "lounge1Before",
        beforeImgStyle: { objectFit: "contain" },
        afterImg: "lounge1After",
        afterStyle: {},
      },
      {
        type: "comparison",
        size: "md",
        beforeImg: "dining1Before",
        beforeImgStyle: { objectFit: "contain" },
        afterImg: "dining1After",
        afterStyle: {},
      },
    ],
    col2: [
      {
        type: "comparison",
        size: "lg",
        beforeImg: "kitchen1Before",
        beforeImgStyle: { objectFit: "contain" },
        afterImg: "kitchen1After",
        afterStyle: {},
      },

      {
        type: "comparison",
        size: "sm",
        beforeImg: "lounge3Before",
        beforeImgStyle: { objectFit: "contain" },
        afterImg: "lounge3After",
        afterStyle: {},
      },
      { type: "after", size: "lg", afterImg: "dining2After" },
    ],
  },
  {
    name: "separate-lounge",
    textColumn: "left",
    col1: [
      {
        type: "text",
        title: "Separate Lounge",
        text: `This room was able to be closed off for heating and noise purposes with the glass doors 
        allowing the room to feel large and also enabling lots of light to flow into the dining which was 
        previously dark and gloomy.`,
      },
      {
        type: "comparison",
        size: "md",
        beforeImg: "separatelounge3Before",
        beforeImgStyle: { objectFit: "contain" },
        afterImg: "separatelounge3After",
        afterStyle: {},
      },
    ],
    col2: [
      {
        type: "comparison",
        size: "lg",
        beforeImg: "separatelounge1Before",
        beforeImgStyle: { objectFit: "contain" },
        afterImg: "separatelounge1After",
        afterStyle: {},
      },
      { type: "after", size: "md", afterImg: "separatelounge2After" },
    ],
  },
  {
    name: "bathroom",
    textColumn: "left",
    col1: [
      {
        type: "text",
        title: "Bathroom",
        text: `The Bathroom and ensuite were in good condition and actually fit 
        the style of the home well. <br/> We reconditioned the cabinetry and 
        replaced the fittings accordingly along with a repaint. `,
      },
      { type: "after", size: "lg", afterImg: "bathroom3After" },
    ],
    col2: [
      { type: "after", size: "lg", afterImg: "bathroom2After" },
      {
        type: "comparison",
        size: "md",
        beforeImg: "bathroom1Before",
        beforeImgStyle: { objectFit: "contain" },
        afterImg: "bathroom1After",
        afterStyle: {},
      },
    ],
  },
  {
    name: "bedroom",
    textColumn: "left",
    col1: [
      {
        type: "text",
        title: "Bedrooms",
        text: `Each bedroom had a beautiful view. We wanted to add some interest to the bedrooms. 
        We love how the wall panels we added came out paired with the wall colours and pendant lights. `,
      },
      {
        type: "comparison",
        size: "md",
        beforeImg: "bedroom1Before",
        beforeImgStyle: { objectFit: "contain" },
        afterImg: "bedroom1After",
        afterStyle: {},
      },
      {
        type: "comparison",
        size: "lg",
        beforeImg: "bedroom3Before",
        beforeImgStyle: { objectFit: "contain" },
        afterImg: "bedroom3After",
        afterStyle: {},
      },
    ],
    col2: [
      {
        type: "comparison",
        size: "lg",
        beforeImg: "bedroom2Before",
        beforeImgStyle: { objectFit: "contain" },
        afterImg: "bedroom2After",
        afterStyle: {},
      },
      { type: "after", size: "sm", afterImg: "bedroom4After" },
    ],
  },
  {
    name: "exterior",
    textColumn: "left",
    col1: [
      {
        type: "text",
        title: "Exterior",
        text: `The existing cladding of the home was repaired, repainted and new windows in 90% of the home.`,
      },
      { type: "after", size: "lg", afterImg: "exterior4After" },
      {
        type: "comparison",
        size: "md",
        beforeImg: "exterior1Before",
        beforeImgStyle: { objectFit: "contain" },
        afterImg: "exterior1After",
        afterStyle: {},
      },
      {
        type: "content-text",
        text: `
        We made a large but necessary decision to replace the large retaining wall and widen the driveway.`,
      },
      {
        type: "comparison",
        size: "sm",
        beforeImg: "exterior2Before",
        beforeImgStyle: { objectFit: "contain" },
        afterImg: "exterior2After",
        afterStyle: {},
      },
    ],
    col2: [
      { type: "after", size: "lg", afterImg: "exterior5After" },
      {
        type: "content-text",
        text: `
        Adding a garage was essential in our eyes, and tiling the large sundeck above... what a fantastic place for entertaining. The dark tiles radiating heat well after the sun goes down.
    `,
      },
      { type: "after", size: "md", afterImg: "exterior6After" },
      {
        type: "comparison",
        size: "lg",
        beforeImg: "exterior3Before",
        beforeImgStyle: { objectFit: "contain" },
        afterImg: "exterior3After",
        afterStyle: {},
      },
    ],
  },
]
