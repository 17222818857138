import React from "react"

import "../global-project-styles.scss"

import useExitAnimationFade from "../../../animations/useExitAnimationFade"

import ProjectHero from "../ProjectHero"
import useProjectImagesParallax from "../../../animations/useParallaxItems"
import useProjectRevealAnimations from "../../../animations/useProjectRevealAnimations"
import RepeatableSection from "../RepeatableSection"

import { landsdowneData } from "./landsdowneData"

import ProjectEndContent from "../ProjectEndContent"
import SEO from "../../seo"

const Landsdowne = ({ data: pageData }) => {
  useExitAnimationFade()
  useProjectImagesParallax()
  useProjectRevealAnimations()

  console.log("pageData", pageData)

  return (
    <div className="page project landsdowne">
      <SEO
        title="Landsdowne"
        description="A large modern family home with a french twist."
      />

      <ProjectHero
        pageData={pageData}
        heroImg="exterior4After"
        title="landsdowne"
        description="A large modern family home with a french twist."
      />

      {landsdowneData.map((sectionData, index) => (
        <RepeatableSection
          pageData={pageData}
          key={index}
          section={sectionData}
        />
      ))}

      <ProjectEndContent to="/soleares/" />
    </div>
  )
}

export default Landsdowne
