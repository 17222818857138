import { graphql } from "gatsby"
import Landsdowne from "../components/projects/landsdowne"

export default Landsdowne

export const query = graphql`
  query {
    bathroom1After: file(
      relativePath: { eq: "projects/landsdowne/bathroom1After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bathroom1Before: file(
      relativePath: { eq: "projects/landsdowne/bathroom1Before.jpeg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bathroom2After: file(
      relativePath: { eq: "projects/landsdowne/bathroom2After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bathroom3After: file(
      relativePath: { eq: "projects/landsdowne/bathroom3After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bedroom1After: file(
      relativePath: { eq: "projects/landsdowne/bedroom1After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bedroom1Before: file(
      relativePath: { eq: "projects/landsdowne/bedroom1Before.jpeg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bedroom2After: file(
      relativePath: { eq: "projects/landsdowne/bedroom2After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bedroom2Before: file(
      relativePath: { eq: "projects/landsdowne/bedroom2Before.jpeg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bedroom3After: file(
      relativePath: { eq: "projects/landsdowne/bedroom3After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bedroom3Before: file(
      relativePath: { eq: "projects/landsdowne/bedroom3Before.jpeg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bedroom4After: file(
      relativePath: { eq: "projects/landsdowne/bedroom4After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    dining1After: file(
      relativePath: { eq: "projects/landsdowne/dining1After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    dining1Before: file(
      relativePath: { eq: "projects/landsdowne/dining1Before.jpeg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    dining2After: file(
      relativePath: { eq: "projects/landsdowne/dining2After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    exterior1After: file(
      relativePath: { eq: "projects/landsdowne/exterior1After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 1440, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    exterior1Before: file(
      relativePath: { eq: "projects/landsdowne/exterior1Before.jpeg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    exterior2After: file(
      relativePath: { eq: "projects/landsdowne/exterior2After.jpeg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    exterior2Before: file(
      relativePath: { eq: "projects/landsdowne/exterior2Before.jpeg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    exterior3After: file(
      relativePath: { eq: "projects/landsdowne/exterior3After.jpeg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    exterior3Before: file(
      relativePath: { eq: "projects/landsdowne/exterior3Before.jpeg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    exterior4After: file(
      relativePath: { eq: "projects/landsdowne/exterior4After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    exterior5After: file(
      relativePath: { eq: "projects/landsdowne/exterior5After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    exterior6After: file(
      relativePath: { eq: "projects/landsdowne/exterior6After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    kitchen1After: file(
      relativePath: { eq: "projects/landsdowne/kitchen1After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    kitchen1Before: file(
      relativePath: { eq: "projects/landsdowne/kitchen1Before.jpeg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    lounge1After: file(
      relativePath: { eq: "projects/landsdowne/lounge1After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    lounge1Before: file(
      relativePath: { eq: "projects/landsdowne/lounge1Before.jpeg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    lounge2After: file(
      relativePath: { eq: "projects/landsdowne/lounge2After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    lounge2Before: file(
      relativePath: { eq: "projects/landsdowne/lounge2Before.jpeg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    lounge3After: file(
      relativePath: { eq: "projects/landsdowne/lounge3After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    lounge3Before: file(
      relativePath: { eq: "projects/landsdowne/lounge3Before.jpeg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    separatelounge1After: file(
      relativePath: { eq: "projects/landsdowne/separatelounge1After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    separatelounge1Before: file(
      relativePath: { eq: "projects/landsdowne/separatelounge1Before.jpeg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    separatelounge2After: file(
      relativePath: { eq: "projects/landsdowne/separatelounge2After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    separatelounge3After: file(
      relativePath: { eq: "projects/landsdowne/separatelounge3After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    separatelounge3Before: file(
      relativePath: { eq: "projects/landsdowne/separatelounge3Before.jpeg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
